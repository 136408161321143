import React from 'react'
import './Footer.scss'
import LogoCCl from "../../../assets/images/logo-ccl.png";
import {graphql, Link, useStaticQuery} from "gatsby";

const Footer = () => {


  const {allContentfulContenidoTransversal} = useStaticQuery(
    graphql`
        query Footer_Query {
            allContentfulContenidoTransversal {
                nodes {
                    redesSociales {
                        redireccion
                        icono
                        titulo
                    }
                    logo {
                        file {
                            url
                        }
                        gatsbyImageData
                        description
                    }
                }
            }

        }

    `
  )
  const contentInfo = allContentfulContenidoTransversal.nodes[0]


  const activeMenu = {
    color: '#3DA9FC',
    fontFamily: 'Montserrat-Bold'
  }

  return (
    <footer className='footer'>
      <div className='footer-wrapper container'>
        <div className='footer__logo'>
          <img src={contentInfo.logo.file.url} alt={contentInfo.logo.description}/>
        </div>

        <ul className="footer__social">
          {contentInfo.redesSociales.map((social, i) => {
            return(
              <li key={`social-${i}`}> <a href={social.redireccion} target="_blank" title={social.titulo}> <i
                className={social.icono}></i></a></li>
            )
          })}


        </ul>

      </div>

    </footer>
  )
}

export default Footer
