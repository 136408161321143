import React, {useState} from 'react'
import './Contact.scss'
import Input from "../../atoms/Input/Input";
import Button from "../../atoms/Button/Button";
import Modal from "../../molecules/Modal/Modal";
import Loading from "../../atoms/loading/loading";

const Contact = () => {


  const emailRegex = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/

  const [fullName, setFullName] = useState("")
  const [company, setCompany] = useState("")
  const [mail, setMail] = useState("")
  const [telphone, setTelphone] = useState("")
  const [message, setMessage] = useState("")

  const [fullNameError, setFullNameError] = useState(false)
  const [companyError, setCompanyError] = useState(false)
  const [mailError, setMailError] = useState(false)
  const [telphoneError, setTelphoneError] = useState(false)
  const [messageError, setMessageError] = useState(false)

  const [modalSendOk, setModalSendOk] = useState(false)
  const [modalSendError, setModalSendError] = useState(false)

  const [sending, setSending] = useState(false)

  const handleSubmit = e => {
    e.preventDefault()

    if (!haveErrors()) {
      sendEmail()
    }
  }

  const haveErrors = () => {


    if (fullName === "" || fullName === " ") {
      setFullNameError("Este campo es requerido")
    } else {
      setFullNameError(false)
    }


    if (company === "" || company === " ") {
      setCompanyError("Este campo es requerido")
    } else {
      setCompanyError(false)
    }

    if (telphone === "" || telphone === " ") {
      setTelphoneError("Este campo es requerido")
    } else {
      setTelphoneError(false)
    }

    if (mail === "") {
      setMailError("Este campo es requerido")
    } else if (!(emailRegex.test(mail))) {
      setMailError("Correo inválido")
    } else {
      setMailError(false)
    }

    if (message === "" || message === " ") {
      setMessageError("Este campo es requerido")
    } else if (message.length < 50) {
      setMessageError("Ingresa como minimo 50 caracteres")
    } else {
      setMessageError(false)
    }

    return (fullName === "" || fullName === " ") ||
      (company === "" || company === " ") ||
      (telphone === "" || telphone === " ") ||
      (mail === "") || (!(emailRegex.test(mail))) ||
      (message === "" || message === " ") || message.length < 50
  }


  const sendEmail = () => {
    setSending(true)
    let params = {
      user_id: process.env.EMAILJS_USER_ID,
      service_id: process.env.EMAILJS_SERVICE_ID,
      template_id: process.env.EMAILJS_TEMPLATE_CONTACT,
      template_params: {
        "fullName": fullName,
        "company": company,
        "mail": mail,
        "tel": telphone,
        "message": message
      }
    }


    let headers = {
      "Content-type": "application/json"
    }

    let options = {
      method: "POST",
      headers: headers,
      body: JSON.stringify(params)
    }

    fetch("https://api.emailjs.com/api/v1.0/email/send", options)
      .then((res) => {
        if (res.ok) {
          setModalSendOk(true)
          setFullName("")
          setCompany("")
          setTelphone("")
          setMail("")
          setMessage("")
        } else {
          console.log("error")
          setModalSendError(true)
        }
      })
      .catch((error) => {
        console.log("Oops... " + error)
        setModalSendError(true)
      })
      .finally(() => {
        setSending(false)
      })

  }


  return (
    <>
      {modalSendOk && (
        <Modal modalForm={true} setShowModal={setModalSendOk}>
          <h3 className="modal__title">Muchas Gracias Por Comunicarse Con Nosotros</h3>
          <p className="modal__paragraph">Muchas gracias por contactarnos. Tus comentarios y sugerencias son muy
            valiosos para nosotros. Pronto un funcionario de nuestra empresa atenderá tus solicitudes e inquietudes.</p>
        </Modal>
      )}

      {modalSendError && (
        <Modal modalForm={true} setShowModal={setModalSendError}>
          <h3 className="modal__title error">¡Error Al Enviar El Formulario!</h3>
          <p className="modal__paragraph error">Hemos tenido problemas al enviar el formulario, por favor inténtalo
            nuevamente.</p>
        </Modal>
      )}
      <section className='contact'>
        <div className='contact__wrapper container container--medium'>
          <h2>Contáctenos</h2>
          <form className='contact__form' onSubmit={handleSubmit}>
            <Input
              label='Nombre Completo'
              type='text'
              value={fullName}
              onChange={e => setFullName(
                e.target.value
                  .replace(/[^a-zA-ZáéíóúÁÉÍÓÚñÑ´ ]/g, "")
                  .replace(/ {2}/g, " ")
                  .slice(0, 60)
              )}
              errors={fullNameError}
            />
            <Input
              label='Empresa'
              type='text'
              value={company}
              onChange={e => setCompany(
                e.target.value
                  .replace(/ {2}/g, " ")
                  .slice(0, 60)
              )}
              errors={companyError}
            />
            <Input
              label='Correo'
              type='email'
              value={mail}
              onChange={e => setMail(
                e.target.value
                  .replace(/ /g, "")
                  .slice(0, 80)
              )}
              errors={mailError}
            />
            <Input
              label='Telefono'
              type='tel'
              value={telphone}
              onChange={e => setTelphone(
                e.target.value
                  .replace(/\D/, "")
                  .slice(0, 10)
              )}
              errors={telphoneError}
            />
            <Input
              label='Mensaje'
              type='text-area'
              value={message}
              onChange={e => setMessage(
                e.target.value
                  .replace(/ {2}/g, " ")
                  .slice(0, 500)
              )}
              errors={messageError}

            />
            <div className='contact__submit'>
              {sending ? (
                <Loading/>
              ) : (
                <Button txt='Enviar' disabled={sending}/>
              )}
            </div>

          </form>


        </div>
      </section>
    </>

  )
}

export default Contact
