import React from 'react'
import './Input.scss'

const Input = ({label, type, onChange, value, errors}) => {
  return (
    <>
      {['text', 'tel', 'email'].includes(type) && (
        <div className={`input ${errors ? 'error' : ''}`}>
          <label className='input__label'>{label}</label>
          <input  className={`input__input ${errors ? 'error' : ''}`} type={type} onChange={onChange} value={value}/>
        </div>
      )}

      {type === 'text-area' && (
        <div className={`input ${errors ? 'error' : ''}`}>

          <label className='input__label'>{label}</label>
          <textarea className={`input__text-area ${errors ? 'error' : ''}`} onChange={onChange} value={value}/>
        </div>
      )}

      {errors && <p className='input__error'>{errors}</p>}


    </>
  )
}

export default Input
