import React from 'react'
import './BurgerMenu.scss'

const BurgerMenu = ({menuActice, setMenuActice}) => {
  return (
    <div
      className={`burger-menu ${menuActice ? "active" : ""}`}
      onClick={() => setMenuActice(!menuActice)}
    >
      <span/>
    </div>
  )
}

export default BurgerMenu
