import React from 'react'
import './Button.scss'
const Button = ({txt, disabled}) => {
  return (
    <button className='button' disabled={disabled}>
      {txt}
    </button>
  )
}

export default Button
