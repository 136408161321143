import React, {useState} from 'react'
import './Header.scss'

import LogoCCl from '../../../assets/images/logo-ccl.png'
import useMobile from "../../../hooks/useMobile"

import {graphql, Link, useStaticQuery} from "gatsby";
import BurgerMenu from "../../atoms/BurgerMenu/BurgerMenu";

const Header = () => {


  const {allContentfulContenidoTransversal} = useStaticQuery(
    graphql`
        query Header_Query {
            allContentfulContenidoTransversal {
                nodes {
                    logo {
                        file {
                            url
                        }
                        gatsbyImageData
                        description
                    }
                }
            }

        }

    `
  )
  const contentInfo = allContentfulContenidoTransversal.nodes[0]

  const isMobile = useMobile()

  const [menuActice, setMenuActice] = useState(false)

  const activeMenu = {
    color: '#3DA9FC',
    fontFamily: 'Montserrat-Bold'
  }

  return (
    <header className='header'>
      <div className='header-wrapper container'>
        <div className='header__logo'>
          <Link to={'/'}>
            <img src={contentInfo.logo.file.url} alt={contentInfo.logo.description}/>
          </Link>
        </div>
        {isMobile && (<BurgerMenu menuActice={menuActice} setMenuActice={setMenuActice}/>)}

        <nav className={`header__menu ${isMobile ? 'mobile' : ''} ${menuActice ? "active" : ""}`}>
          <Link to={'/'} activeStyle={activeMenu}>Inicio</Link>
          <Link to={'/servicios'} activeStyle={activeMenu}>Servicios</Link>
          <Link to={'/nosotros'} activeStyle={activeMenu}>Nosotros</Link>
          <Link to={'/contactenos'} activeStyle={activeMenu}>Contactenos</Link>
        </nav>

      </div>

    </header>
  )
}

export default Header
