import React, { useEffect, useState } from "react";
import "./Layout.scss";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import Modal from "../../molecules/Modal/Modal";

const Layout = ({ children }) => {
  const [state, setState] = useState(false);

  useEffect(() => {
    setState(true);
  }, []);

  return (
    <>
      <Modal setShowModal={setState} close={false}>
        <p>
          Nos complace anunciar que hemos cambiado nuestro nombre de empresa. A
          partir de ahora, seremos conocidos como{" "}
          <b>Integral Cold Logistics S.A.S. </b>
          Este cambio refleja nuestra evolución y crecimiento continuo.
        </p>

        <p>
          Apreciamos tu apoyo y confianza en <b>Cold Chain Logistics S.A.S.</b>{" "}
          Con el nuevo nombre, esperamos fortalecer aún más nuestra relación
          contigo y continuar proporcionándote soluciones de calidad.
        </p>

        <p>
          Para visitar nuestro nuevo sitio, simplemente haz clic en el siguiente
          enlace: <a href={"https://www.iclogis.com/"}>iclogis.com</a>. Serás
          redirigido directamente a nuestra página actualizada,
        </p>
      </Modal>
      <Header />
      <div className="content">
        <main className="main-container">{children}</main>
        <Footer />
      </div>
    </>
  );
};

export default Layout;
