import React, { useEffect } from "react";
import "./Modal.scss";

const Modal = ({
  children,
  showModal,
  setShowModal,
  modalForm = false,
  actions,
  close = true,
}) => {
  useEffect(() => {
    bodyOverFlowBlock("open");
  });

  const bodyOverFlowBlock = (action) => {
    const body = document.getElementsByTagName("body")[0];
    if (action === "open") {
      body.classList.add("body-block");
    } else {
      body.classList.remove("body-block");
      if (actions) {
        actions();
      }
    }
  };

  return (
    <div className="modal">
      <div className={`modal__content ${modalForm ? "formResp" : ""}`}>
        {close && (
          <>
            <span
              className="modal__close"
              onClick={() => {
                bodyOverFlowBlock("close");
                setShowModal(false);
              }}
            >
              <i className="fas fa-times"></i>
            </span>
          </>
        )}

        {children}
      </div>
    </div>
  );
};

export default Modal;
