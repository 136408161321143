import React from 'react'
import './Clients.scss'

import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

import {graphql, useStaticQuery} from "gatsby";

const Clients = () => {

  const { allContentfulContenidoTransversal } = useStaticQuery(
    graphql`
        query Clients_Query {
            allContentfulContenidoTransversal {
                nodes {
                    clientes {
                        titulo
                        clientes {
                            file {
                                url
                            }
                            gatsbyImageData
                            description
                        }
                    }
                }
            }
        }

    `
  )
  const contentInfo = allContentfulContenidoTransversal.nodes[0].clientes
  const totalClients = contentInfo.clientes.length


  const settings = {
    speed: 1500,
    infinite: true,
    slidesToShow: totalClients >= 4 ? 4 : totalClients,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: false,
    dots: true,


    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: totalClients >= 3 ? 3 : totalClients,

        }
      },
      {
        breakpoint: 820,
        settings: {
          slidesToShow: 2,
        }
      },
      {
        breakpoint: 500,
        settings: {
          slidesToShow: 1,
        }
      }
    ]
  }


  return (
    <section className='clients'>
      <div className='clients__carousel container'>
        <h2>{contentInfo.titulo}</h2>
        <Slider {...settings}>
          {contentInfo.clientes.map((cliente, i) => {
            return(
              <div className='clients__client' key={`cliente-${i}`}>
                <img src={cliente.file.url} alt={cliente.description}/>
              </div>
            )
          })}



        </Slider>

      </div>
    </section>
  )
}

export default Clients
